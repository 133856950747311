* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  scroll-behavior: smooth;
  box-sizing: border-box;
  font-family: "Alata", Helvetica;
  font-display: swap;
}

html,
body {
  background-color: black;
  overflow-x: hidden;

  // Firefox
  scrollbar-width: thin;
  scrollbar-color: rgb(20, 20, 20) rgb(216, 216, 216);
  
  // Chrome & Safari
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-track {
      background: rgb(216, 216, 216); 
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(20, 20, 20);
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(32, 32, 32); 
  }

  .modal, .modal-backdrop {
    z-index: 2000 !important;
  }

  .fixed-bottom {
    bottom: -1px !important;
  }

  .w-100 {
    min-width: 100%;
  }

  .modal-footer {
    .btn {
      margin: 0 auto;
      color: black;
      border: 1px solid black;

      &:focus {
        box-shadow: none !important;
      }

      &:active {
        position: relative;
        top: 1px;
        color: white;
        background-color: black;
      }

      &:hover {
        color: white;
        background-color: black;
      }
    }
  }

  // UP Button CSSTransition
  .up-enter {
    opacity: 0;
  }
  .up-enter-active {
    opacity: 1;
    transition: opacity 1500ms;
  }
  .up-exit {
    opacity: 1;
  }
  .up-exit-active {
    opacity: 0;
    transition: opacity 1500ms;
  }

  .backdrop-blur-top {
    top: 15px;
    opacity: 0.25;
  }

  .backdrop-blur-btm {
    bottom: 15px;
    opacity: 0.25;
  }

  .scroll-up {
    position: fixed;
    top: 94%;
    right: 0;
    transform: translate(-50%, -50%);
    z-index: 1500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.7);
    padding: 1rem;
    border: 1px solid white;
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    transition: 0.5s all ease;

    .fa-angle-double-up {
      font-size: 1.6rem;
      color: white;
    }

    &:active {
      position: fixed;
      top: 93.7%;
    }
  }

  .btn,
  .close {
    &:focus {
      box-shadow: none !important;
      outline: none !important;
    }
  }

  .bm-menu {
    background: white;

    .bm-item:focus {
      outline: none !important;
    }

    .side-nav {
      z-index: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .logo-area {
        padding: 1rem 0;
        background-color: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .close-btn {
          position: absolute;
          top: 0.6rem;
          right: 15rem;

          .fa-times {
            color: black;
          }
        }

        img {
          width: 4.07rem;
          height: 2.65rem;
          filter: invert(100%);
        }
      }

      #span-line {
        span {
          display: block;
          height: 0.01rem;
          margin: 0.1rem auto;
          width: 100%;
          background-color: rgba($color: #000000, $alpha: 0.2);
        }
      }

      .side-menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: white;

        .nav-list {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          margin: 0;

          .btn-home,
          .btn-menu,
          .btn-recipes,
          .btn-about,
          .btn-contact {
            cursor: pointer;
            color: black;
            font-size: 1.2rem;
            font-weight: bold;
            margin: 0.5rem 0;
            padding: 0.5rem 2rem;
          }

          .btn {
            font-size: 1.2rem;
            font-weight: bold;
            cursor: pointer;
            padding: 0.3rem 0.7rem;
            margin: 0.5rem 0;
            margin-bottom: 1rem !important;
            border-radius: 0;
            color: black;
            border: 1px solid black;

            &:focus {
              box-shadow: none !important;
            }

            &:active {
              color: white;
              background-color: black;
            }

            &:hover {
              color: white;
              background-color: black;
            }
          }
        }
      }

      #span-line2 {
        span {
          display: block;
          height: 0.01rem;
          margin: 0.1rem auto;
          width: 100%;
          background-color: rgba($color: #000000, $alpha: 0.2);
        }
      }

      .side-footer {
        width: 100%;
        height: auto;
        background: white;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;

        .footer-data {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 1rem 0;

          #copy {
            color: black;
            font-size: 0.75rem;
          }
        }
      }
    }
  }

  .back-button {
    position: absolute;
    top: 4%;
    left: 4%;
    background-color: black;

    .btn {
      border-radius: 0;

      &:focus {
        box-shadow: none !important;
      }
    }
  }

  #text {
    padding-top: 1.5rem;
  }

  .title-sep,
  #text {
    font-size: 1rem;
    text-align: center;
    background-color: white;

    .link-button {
      outline: none;
      border: none;
      background-color: transparent;
      text-decoration: underline;
    }

    #meet {
      margin: 0;
      font-weight: bold;
      color: black;
      padding: 20px 0;

      @media (max-width: 360px) {
        font-weight: normal;
      }
    }

    #pachet {
      margin: 0;
      font-weight: bold;
      color: black;
      padding: 20px 0 100px;

      @media (max-width: 1200px) {
        padding: 0 0 40px;
        font-weight: normal;
      }

      @media (max-width: 360px) {
        font-weight: normal;
      }
    }

    #meet2 {
      margin: 0;
      font-weight: bold;
      color: black;

      @media (max-width: 360px) {
        font-weight: normal;
      }
    }

    span {
      display: block;
      position: relative;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 0.01rem;
      width: 50%;
      background-color: black;
    }
  }

  .main-nav {
    z-index: 1;
    width: 100%;
    height: 5rem;
    background-color: white;
    box-shadow: 0 0.5px 3px 0 black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .logo-area {
      padding: 0 3rem;
      img {
        width: 4.07rem;
        height: 2.65rem;
        filter: invert(100%);
      }
    }

    .main-menu {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .nav-list {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 0 1rem;

        .btn-home,
        .btn-menu,
        .btn-recipes,
        .btn-about,
        .btn-contact {
          cursor: pointer;
          color: black;
          font-size: 1.2rem;
          font-weight: bold;
          padding: 0.3rem 1.5rem;
          border: 1px solid white;
          transition: 0.5ms ease-in-out;

          &:active {
            position: relative;
            top: 1px;
          }

          &:hover {
            transition: 1s all ease;
            border: 1px solid black;
          }
        }

        .btn {
          font-size: 1.2rem;
          font-weight: bold;
          cursor: pointer;
          padding: 0.3rem 0.7rem;
          margin: 0 0.5rem;
          border-radius: 0;
          color: black;
          border: 1px solid black;

          &:active {
            position: relative;
            top: 1px;
            color: white;
            background-color: black;
          }

          &:focus {
            box-shadow: none !important;
          }

          &:hover {
            color: white;
            background-color: black;
          }
        }
      }

      .fa-bars {
        display: none;
      }
    }
  }

  .home-wrapper {
    .home-page {
      .carousel-caption {
        height: 60%;
        width: 95%;
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;

        h3 {
          font-size: 2rem;
          font-weight: bold;
          white-space: nowrap;
          filter: drop-shadow(1px 1px black);
          margin: 0;
          padding: 0.5rem 1rem;
          border: 0.01rem solid white;
        }

        p {
          filter: drop-shadow(1px 1px black);
          width: 100%;
          font-size: 1.2rem;
          margin: 1rem 0;
        }
      }

      .carousel-img {
        width: 100%;
        height: 93.5vh;
        object-fit: cover;
        filter: brightness(60%);
      }

      .about-text {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-start;
        background-color: white;
        padding: 4rem 2rem;

        .title-sep,
        #text {
          margin: 0;
          font-size: 1rem;
          text-align: center;
          background-color: white;
          padding-left: 5rem;
          padding-right: 5rem;

          @media all and (min-width: 768px) {
            padding-left: 5rem;
            padding-right: 5rem;
          }

          @media (max-width: 560px) {
            padding-left: 2rem;
            padding-right: 2rem;
          }

          @media (max-width: 400px) {
            padding-left: .5rem;
            padding-right: .5rem;
          }

          .link-button {
            outline: none;
            border: none;
            background-color: transparent;
            text-decoration: underline;
          }

          #meet {
            margin: 0;
          }

          span {
            display: block;
            position: relative;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 0.01rem;
            width: 50%;
            background-color: black;
          }
        }
      }
    }

    .parallax2 {
      #img1, #img2 {
        height: 70rem;

        @media (max-width: 412px) {
          height: 25rem;
        }
      }
    }

    .posts {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      padding: 2rem 0 4rem;
      background-color: white;

      .youtube-video {
        width: 560px;
        height: 315px;
        margin: 1rem;
        box-shadow: 0 0 12px rgba($color: #000, $alpha: 0.2);
        transition: 0.5s ease;

        @media (max-width: 560px) {
          width: 280px;
          height: 157.5px;
        }

        @media (max-width: 280px) {
          width: 250px;
          height: 127.5px;
        }
      }

      .card {
        width: 25rem;
        margin: 1rem;

        .card-img-top {
          width: 100%;
          height: 100%;
          object-fit: cover;
          cursor: pointer;
        }

        .card-body {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          justify-content: flex-start;
          border-top: 1px solid rgb(212, 212, 212);
          text-align: left;

          .card-title {
            margin: 0;
          }

          .card-text {
            text-align: left;
          }

          .btn {
            border-radius: 0;
            align-self: left;

            &:focus {
              box-shadow: none !important;
            }
          }
        }
      }
    }

    .parallax22 {
      #img3 {
        height: 50rem;
      }

      .title-sep2,
      #text {
        padding-top: 5+rem;
        padding-bottom: 2rem;
        font-size: 1rem;
        text-align: center;
        background: rgba($color: #000000, $alpha: 0.3);
        color: white;

        .link-button {
          outline: none;
          border: none;
          background-color: transparent;
          text-decoration: underline;
        }

        #meet {
          margin: 0;
        }

        span {
          display: block;
          position: relative;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 0.01rem;
          width: 50%;
          background-color: white;
        }
      }

      .reviews-page {
        background: rgba($color: #000000, $alpha: 0.3);
        height: 42rem;
        

        .carousel {
          width: 100%;
          height: 80%;
          padding-bottom: 8rem !important;

          .carousel-indicators {
            margin-bottom: 4rem !important;
            z-index: 1500;
          }

          .carousel-inner {
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          .card {
            width: 15rem;
            margin: auto;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .card-img-bottom {
              width: 5rem;
              height: 5rem;
              border-radius: 100%;
              object-fit: cover;
              padding: 0.05rem;
              border: 0.01rem solid grey;
            }

            .card-body {
              display: flex;
              flex-wrap: wrap;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              text-align: center;

              span {
                display: block;
                height: 0.01rem;
                margin: 1rem 0;
                width: 60%;
                background-color: rgb(196, 196, 196);
              }

              .card-text {
                text-align: center;
                margin: 0;
                font-weight: lighter;
              }

              .h6 {
                color: black !important;
              }
            }
          }
        }
      }
    }

    #contact-ref {
      overflow: hidden;
      display: block;
      height: 3rem;
      background-color: white;
    }

    .contact-form {
      width: 100%;
      padding-bottom: 0 !important;
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      .text-error {
        color: red;
        margin-top: 0.5rem;
        margin-left: 0.1rem;
        font-weight: normal;
      }

      .alert {
        border-radius: 0;
        margin-top: 1rem;
      }

      .spinner-border {
        margin-top: 1rem;
      }

      .text-err {
        color: red;
        margin: 1.5rem 0;
        font-weight: normal;
      }

      .text-succ {
        color: green;
        margin: 1.5rem 0;
        font-weight: normal;
      }

      h1 {
        font-size: 1.5rem;
        font-weight: 400;
        margin-bottom: 1rem;
      }

      p {
        margin: 0;
      }

      form {
        text-align: left;
        padding: 1rem;
        margin-top: 1rem;
        color: black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 30rem;
        font-weight: bold;

        .form-group {
          width: 100%;

          .form-control {
            margin: 0;
            width: 100%;
          }

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          input[type="number"] {
            -moz-appearance: textfield;
          }

          input,
          textarea {
            // Firefox
            scrollbar-width: thin;
            scrollbar-color: rgb(20, 20, 20) rgb(216, 216, 216);
            resize: none;
            border-radius: 0;
            &:focus {
              box-shadow: none !important;
              border: 1px solid black;
            }
          }
        }

        .btn {
          font-weight: bold;
          border-radius: 0;
          margin: 0 auto;
          margin-top: 1rem !important;
          color: black;
          border: 1px solid black;

          &:focus {
            box-shadow: none !important;
          }

          &:active {
            position: relative;
            top: 1px;
            color: white;
            background-color: black;
          }

          &:hover {
            color: white;
            background-color: black;
          }
        }
      }
    }

    .footer {
      width: 100%;
      height: auto;
      background: black;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;

      .sm-icons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 0 !important;
        margin-bottom: 1rem;

        a {
          text-decoration: none;

          label {
            color: white;
            margin: 0;
            margin-top: 0.3rem !important;
            font-size: 0.7rem;
            cursor: pointer;
          }
        }

        #fb {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 0 1rem;
          padding: 0.5rem;

          .fa-facebook {
            font-size: 1.5rem;
            color: white;
          }
        }

        #ig {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 0 1rem;
          padding: 0.5rem;

          .fa-instagram,
          .fa-youtube {
            font-size: 1.5rem;
            color: white;
          }
        }

        #yt {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 0 1rem;
          padding: 0.5rem;

          .fa-instagram,
          .fa-youtube {
            font-size: 1.5rem;
            color: white;
          }
        }
      }

      .footer-sep {
        hr {
          display: block;
          position: relative;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 0.5px;
          width: 40vw;
          background-color: white;
        }
      }

      .footer-data {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
        margin-bottom: 2rem;

        #copy {
          color: white;
          font-size: 0.8rem;
          margin: 0;
        }
      }
    }
  }
}

// Not Found Page

.not {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;

  .btn {
    position: absolute;
    top: 4%;
    left: 5%;
    font-weight: bold;
    border-radius: 0;

    &:focus {
      box-shadow: none !important;
    }
  }

  #not-found {
    font-size: 1.5rem;
    font-weight: bold;
    color: black;
    text-align: center;
  }

  .fa-exclamation-triangle {
    font-size: 5rem;
    margin: 2rem;
    color: black;
  }
}

// Masterclass
.master-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;

  .masterclass-design {
    margin-top: 6rem;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media (min-width: 4096px) {
      margin-top: 20rem;
    }
    @media (max-width: 4095px) {
      margin-top: 12rem;
    }
    @media (max-width: 2047px) {
      margin-top: 8rem;
    }
    @media (max-width: 640px) {
      margin-top: 3rem;
    }
  }

  .video-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;

    .container {
      max-width: 600px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 6rem;

      h3 {
        max-width: 560px;
        text-align: center;
      }
    }

    .video-player-rv {
      width: 560px;
      height: 315px;
      margin: 1rem;
      box-shadow: 0 0 12px rgba($color: #000, $alpha: 0.2);
      transition: 0.5s ease;

      &:focus {
        outline: none;
      }

      @media (max-width: 560px) {
        width: 280px;
        height: 157.5px;
      }

      @media (max-width: 280px) {
        width: 250px;
        height: 127.5px;
      }
    }

    .masterclass-footer {
      height: 8vh;
      width: 100%;
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        margin: 0;
        color: black;
        font-size: 0.8rem;
      }
    }
  }

  .text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    .coming-soon {
      text-align: center;
      margin: 3rem 0;
    }
  }

  .lds-spinner {
    position: absolute;
    top: 42%;
    left: 50%;
    transform: scale(0.4) translate(-50%, -50%);
    color: official;
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #fff;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .first-step {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: white;
    transition: 300ms ease;

    form {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .form-group {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 50%;
        margin: 0;

        .form-control {
          margin-bottom: 0.5rem;
          border-radius: 0;
        }

        .error-container {
          width: 90%;
          height: 20px;
          margin-bottom: 1rem !important;
          margin: 0 auto;

          .text-error {
            font-size: .85rem;
            color: crimson;
          }

          .alert {
            text-align: center;
          }
        }
      }

      
    }
  }

  .second-step {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: white;
    transition: 300ms ease;
    transition-delay: 300ms;

    form {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin: 0 auto;

      .StripeElement {
        height: 40px;
        padding: 10px 12px;
        width: 100%;
        color: #32325d;
        background-color: white;
        border: 1px solid transparent;
        border-radius: 0;
        box-shadow: 0 1px 3px 0 #e6ebf1;
        -webkit-transition: box-shadow 150ms ease;
        transition: box-shadow 150ms ease;
        margin-bottom: 0.5rem;
      }
      .StripeElement--focus {
        box-shadow: 0 1px 3px 0 #cfd7df;
      }
      .StripeElement--invalid {
        border-color: #fa755a;
      }
      .StripeElement--webkit-autofill {
        background-color: #fefde5 !important;
      }
    }
  }

  .scaled-out {
    transform: scale(0);
    display: none;
  }

  .scale-in {
    transform: scale(1);
    display: block;
  }

  .hide-scale-out {
    transform: scale(0);
    display: none;
  }

  .btn {
    align-self: center;
    margin-top: 1.5rem;
    border-radius: 0;
  }
}

// YouTube Background
.container{
	position: relative;
  overflow: hidden;
  
  .videoContainer{
    position: absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;
    width:100%;
    height:100%;
    z-index: -1;
    
    .videoInnerContainer{
      width:100%;
      height:100%;
      
      .overlay{
        position: absolute;
        left:0;
        top:0;
        right:0;
        bottom:0;
        z-index: 5;
        
        .videoIframe {
          position: relative;
          left:0;
          top:0;
          right:0;
          bottom:0;
          width: 100%;
          height: 100%;
          z-index:0;
        }
      }
    }
  }
}

/* Media queries */

@media all and (max-width: 1024px) {
  .main-nav {
    z-index: 1;
    width: 100%;
    height: 5rem;
    background-color: white;
    box-shadow: 0 0.5px 3px 0 black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .logo-area {
      padding: 0 2rem !important;
      img {
        width: 4rem;
        filter: invert(100%);
      }
    }

    .main-menu {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .nav-list {
        display: none !important;
      }

      .fa-bars {
        display: flex !important;
        cursor: pointer;
        margin: 1rem 2rem;
        font-size: 2rem;
      }
    }
  }
}

@media all and (max-width: 768px) {
  form {
    width: 80% !important;
  }
}
